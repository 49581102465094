import { Injectable } from '@angular/core';
import { ResponseResource } from '@service/http/response';
import { Observable } from 'rxjs';
import { Banner, BannerTypeEnum } from './banner';
import { ApiService } from '@shared/services/api.service';

@Injectable({
    providedIn: 'root',
})
export class BannerService extends ApiService<Banner> {
    public baseUrl = '/v2/banners';

    /**
     * Retrieve active banner for given type
     *
     * @param {type}
     *
     * @returns banner
     *
     * @author Sander van Ooijen <svanooijen@bettercollective.com>
     * @author Koen Lukkien <klukkien@bettercollective.com>
     * @version 1.0.1
     */
    public showActive(
        type: BannerTypeEnum,
    ): Observable<ResponseResource<Banner>> {
        return this.httpService.get('/v2/active-banner', {
            params: {
                type,
            },
        });
    }
}
